export const BALL_COLORS = {
  BLUE: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48],
  RED: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46],
  GREEN: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]
}

export const ZODIAC_ORDER = [
  'rat', 'ox', 'tiger', 'rabbit', 'dragon', 'snake',
  'horse', 'goat', 'monkey', 'rooster', 'dog', 'pig'
]

export const BASE_ZODIAC_NUMBERS = {
  rat: [1, 13, 25, 37, 49],
  ox: [12, 24, 36, 48],
  tiger: [11, 23, 35, 47],
  rabbit: [10, 22, 34, 46],
  dragon: [9, 21, 33, 45],
  snake: [8, 20, 32, 44],
  horse: [7, 19, 31, 43],
  goat: [6, 18, 30, 42],
  monkey: [5, 17, 29, 41],
  rooster: [4, 16, 28, 40],
  dog: [3, 15, 27, 39],
  pig: [2, 14, 26, 38]
}

export const ZODIAC_NAMES = {
  rat: '鼠', ox: '牛', tiger: '虎', rabbit: '兔',
  dragon: '龙', snake: '蛇', horse: '马', goat: '羊',
  monkey: '猴', rooster: '鸡', dog: '狗', pig: '猪'
}

export const ZODIAC_COLORS = {
  rat: 'red',      // 鼠 1
  ox: 'blue',      // 牛 12
  tiger: 'blue',   // 虎 11
  rabbit: 'blue',  // 兔 10
  dragon: 'blue',  // 龙 9
  snake: 'blue',   // 蛇 8
  horse: 'red',    // 马 7
  goat: 'green',   // 羊 6
  monkey: 'green', // 猴 5
  rooster: 'red',  // 鸡 4
  dog: 'red',      // 狗 3
  pig: 'red'       // 猪 2
}

// 修改 lunar 对象，使用更精确的农历数据
const lunar = {
  // 农历数据信息表 1900-2100 年
  // 数据结构说明：
  // [0-3]：表示当年农历正月初一对应的公历月份
  // [4-15]：表示当年农历每个月的大小情况，1 表示大月(30天)，0 表示小月(29天)
  // [16]：表示当年闰月月份，0 表示当年无闰月
  lunarYears: [
    0x04bd8,0x04ae0,0x0a570,0x054d5,0x0d260,0x0d950,0x16554,0x056a0,0x09ad0,0x055d2, // 1900-1909
    0x04ae0,0x0a5b6,0x0a4d0,0x0d250,0x1d255,0x0b540,0x0d6a0,0x0ada2,0x095b0,0x14977, // 1910-1919
    0x04970,0x0a4b0,0x0b4b5,0x06a50,0x06d40,0x1ab54,0x02b60,0x09570,0x052f2,0x04970, // 1920-1929
    0x06566,0x0d4a0,0x0ea50,0x06e95,0x05ad0,0x02b60,0x186e3,0x092e0,0x1c8d7,0x0c950, // 1930-1939
    0x0d4a0,0x1d8a6,0x0b550,0x056a0,0x1a5b4,0x025d0,0x092d0,0x0d2b2,0x0a950,0x0b557, // 1940-1949
    0x06ca0,0x0b550,0x15355,0x04da0,0x0a5b0,0x14573,0x052b0,0x0a9a8,0x0e950,0x06aa0, // 1950-1959
    0x0aea6,0x0ab50,0x04b60,0x0aae4,0x0a570,0x05260,0x0f263,0x0d950,0x05b57,0x056a0, // 1960-1969
    0x096d0,0x04dd5,0x04ad0,0x0a4d0,0x0d4d4,0x0d250,0x0d558,0x0b540,0x0b6a0,0x195a6, // 1970-1979
    0x095b0,0x049b0,0x0a974,0x0a4b0,0x0b27a,0x06a50,0x06d40,0x0af46,0x0ab60,0x09570, // 1980-1989
    0x04af5,0x04970,0x064b0,0x074a3,0x0ea50,0x06b58,0x055c0,0x0ab60,0x096d5,0x092e0, // 1990-1999
    0x0c960,0x0d954,0x0d4a0,0x0da50,0x07552,0x056a0,0x0abb7,0x025d0,0x092d0,0x0cab5, // 2000-2009
    0x0a950,0x0b4a0,0x0baa4,0x0ad50,0x055d9,0x04ba0,0x0a5b0,0x15176,0x052b0,0x0a930, // 2010-2019
    0x07954,0x06aa0,0x0ad50,0x05b52,0x04b60,0x0a6e6,0x0a4e0,0x0d260,0x0ea65,0x0d530, // 2020-2029
    0x05aa0,0x076a3,0x096d0,0x04afb,0x04ad0,0x0a4d0,0x1d0b6,0x0d250,0x0d520,0x0dd45, // 2030-2039
    0x0b5a0,0x056d0,0x055b2,0x049b0,0x0a577,0x0a4b0,0x0aa50,0x1b255,0x06d20,0x0ada0, // 2040-2049
    0x14b63,0x09370,0x049f8,0x04970,0x064b0,0x168a6,0x0ea50,0x06b20,0x1a6c4,0x0aae0  // 2050-2059
  ],

  // 计算农历某年春节的公历日期
  getSpringFestival: function(year) {
    if (year < 1900 || year > 2100) {
      throw new Error('年份超出范围（1900-2100）');
    }
    
    const yearCode = this.lunarYears[year - 1900];
    const month = ((yearCode >> 12) & 0xF); // 正月对应的公历月份
    const day = (yearCode >> 8) & 0xF;     // 初一对应的公历日期
    
    return new Date(year, month - 1, day);
  },

  // 获取某年某月的天数
  getMonthDays: function(year, month) {
    if (year < 1900 || year > 2100) {
      throw new Error('年份超出范围（1900-2100）');
    }
    const yearCode = this.lunarYears[year - 1900];
    return ((yearCode >> (6 + month)) & 0x1) ? 30 : 29;
  },

  // 获取闰月月份，0表示无闰月
  getLeapMonth: function(year) {
    if (year < 1900 || year > 2100) {
      throw new Error('年份超出范围（1900-2100）');
    }
    return this.lunarYears[year - 1900] & 0xF;
  }
};

// 修改 getZodiacMapByYear 函数中的相关调用
export const getZodiacMapByYear = (year) => {
  try {
    const springFestival = lunar.getSpringFestival(year);
    const currentDate = new Date();
    const baseYear = 1900;
    
    // 如果当前日期在春节前，使用上一年的生肖
    let effectiveYear = year;
    if (currentDate < springFestival) {
      effectiveYear = year - 1;
    }
    
    const offset = (effectiveYear - baseYear) % 12;
    const yearZodiacOrder = [
      ...ZODIAC_ORDER.slice(offset),
      ...ZODIAC_ORDER.slice(0, offset)
    ];

    const zodiacMap = {};
    yearZodiacOrder.forEach((zodiac, index) => {
      zodiacMap[zodiac] = BASE_ZODIAC_NUMBERS[ZODIAC_ORDER[index]];
    });

    return zodiacMap;
  } catch (error) {
    console.error('计算生肖年份出错:', error);
    // 返回默认的生肖映射（使用当前年份的固定映射）
    return BASE_ZODIAC_NUMBERS;
  }
};

// 修改 getZodiacByYearAndNumber 函数中的相关调用
export const getZodiacByYearAndNumber = (year, number) => {
  try {
    const springFestival = lunar.getSpringFestival(year);
    const currentDate = new Date();
    
    let effectiveYear = year;
    if (currentDate < springFestival) {
      effectiveYear = year - 1;
    }
    
    const zodiacMap = getZodiacMapByYear(effectiveYear);
    for (const [zodiac, numbers] of Object.entries(zodiacMap)) {
      if (numbers.includes(Number(number))) {
        return ZODIAC_NAMES[zodiac];
      }
    }
    return '';
  } catch (error) {
    console.error('计算生肖出错:', error);
    return '';
  }
};

export const getBallColor = (number) => {
  const num = parseInt(number)
  if (BALL_COLORS.BLUE.includes(num)) return 'blue'
  if (BALL_COLORS.RED.includes(num)) return 'red'
  if (BALL_COLORS.GREEN.includes(num)) return 'green'
  return 'default'
}

export const getBallBackgroundImage = (number) => {
  const color = getBallColor(number)
  return `/static/ball/${color}.png`
}

export const BALL_WUXING = {
  METAL: [2, 3, 10, 11, 24, 25, 32, 33, 40, 41],  // 金
  WOOD: [6, 7, 14, 15, 22, 23, 36, 37, 44, 45],   // 木
  WATER: [12, 13, 20, 21, 28, 29, 42, 43],  // 水
  FIRE: [1, 8, 9, 16, 17, 30, 31, 38, 39, 46, 47],   // 火
  EARTH: [4, 5, 18, 19, 26, 27, 34, 35, 48, 49]      // 土
}

export const WUXING_NAMES = {
  METAL: '金',
  WOOD: '木',
  WATER: '水',
  FIRE: '火',
  EARTH: '土'
}

export const getBallWuxing = (number) => {
  const num = parseInt(number)
  if (BALL_WUXING.METAL.includes(num)) return 'METAL'
  if (BALL_WUXING.WOOD.includes(num)) return 'WOOD'
  if (BALL_WUXING.WATER.includes(num)) return 'WATER'
  if (BALL_WUXING.FIRE.includes(num)) return 'FIRE'
  if (BALL_WUXING.EARTH.includes(num)) return 'EARTH'
  return 'default'
}

export const getWuxingName = (wuxing) => {
  return WUXING_NAMES[wuxing] || ''
}

export const getZodiacColor = (zodiac) => {
  return ZODIAC_COLORS[zodiac] || ''
}

// 添加按钮配置转换函数
export const convertButtonConfig = (serverConfig, year = new Date().getFullYear()) => {
    const zodiacMap = getZodiacMapByYear(year);
    
    // 处理服务器返回的配置
    const processButtons = (buttons, type) => {
        switch(type) {
            case 'zodiac':
                return buttons.map(btn => ({
                    ...btn,
                    mainText: ZODIAC_NAMES[btn.value] || btn.mainText,
                    subText: zodiacMap[btn.value]
                        ?.map(num => String(num).padStart(2, '0'))
                        .join(' ') || btn.subText,
                    colorClass: getZodiacColor(btn.value) || btn.colorClass
                }));
            case 'numbers':
                return buttons.map(btn => ({
                    ...btn,
                    colorClass: getBallColor(btn.value) || btn.colorClass
                }));
            default:
                return buttons;
        }
    };

    // 处理每个玩法的配置
    return Object.entries(serverConfig).reduce((acc, [key, config]) => {
        acc[key] = {
            ...config,
            buttons: processButtons(config.buttons || [], key)
        };
        return acc;
    }, {});
};

// 默认配置（当服务器请求失败时使用）
export const defaultButtonConfigs = {
    zodiac: {
        type: 'rect',
        layout: { columns: 3, gap: 20, padding: 20 },
        buttons: ZODIAC_ORDER.map(zodiac => ({
            id: zodiac,
            value: zodiac,
            mainText: ZODIAC_NAMES[zodiac],
            subText: getZodiacMapByYear(new Date().getFullYear())[zodiac]
                .map(num => String(num).padStart(2, '0'))
                .join(' '),
            colorClass: getZodiacColor(zodiac),
            width: 200,
            height: 120
        }))
    },
    numbers: {
        type: 'circle',
        layout: { columns: 7, gap: 16, padding: 20 },
        buttons: Array.from({ length: 49 }, (_, i) => {
            const num = i + 1;
            return {
                id: num,
                value: num,
                number: num,
                colorClass: getBallColor(num)
            };
        })
    },
    colors: {
        type: 'rect',
        layout: { columns: 3, gap: 20, padding: 20 },
        buttons: [
            // 基础波色
            ...['red', 'blue', 'green'].map(color => ({
                id: color,
                value: color,
                mainText: `${color === 'red' ? '红' : color === 'blue' ? '蓝' : '绿'}波`,
                colorClass: color,
                width: 180,
                height: 80
            })),
            // 波色单双组合
            ...['red', 'blue', 'green'].flatMap(color => [
                {
                    id: `${color}_odd`,
                    value: `${color}_odd`,
                    mainText: `${color === 'red' ? '红' : color === 'blue' ? '蓝' : '绿'}单`,
                    colorClass: color,
                    width: 180,
                    height: 80
                },
                {
                    id: `${color}_even`,
                    value: `${color}_even`,
                    mainText: `${color === 'red' ? '红' : color === 'blue' ? '蓝' : '绿'}双`,
                    colorClass: color,
                    width: 180,
                    height: 80
                }
            ])
        ]
    },
    basic: {
        type: 'rect',
        layout: { columns: 4, gap: 20, padding: 20 },
        buttons: [
            { id: 'odd', value: 'odd', mainText: '单', subText: '', colorClass: 'basic-odd', width: 140, height: 80 },
            { id: 'even', value: 'even', mainText: '双', subText: '', colorClass: 'basic-even', width: 140, height: 80 },
            { id: 'big', value: 'big', mainText: '大', subText: '', colorClass: 'basic-big', width: 140, height: 80 },
            { id: 'small', value: 'small', mainText: '小', subText: '', colorClass: 'basic-small', width: 140, height: 80 }
        ]
    }
};

// 格式化投注号码显示
export const formatBetNumber = (number) => {
    // 处理波色
    if (typeof number === 'string') {
        // 处理基础波色
        if (['red', 'blue', 'green'].includes(number)) {
            return number === 'red' ? '红波' : 
                   number === 'blue' ? '蓝波' : 
                   '绿波';
        }
        
        // 处理波色单双组合
        const [color, type] = number.split('_');
        if (color && type && ['red', 'blue', 'green'].includes(color)) {
            const colorText = color === 'red' ? '红' : 
                            color === 'blue' ? '蓝' : 
                            '绿';
            const typeText = type === 'odd' ? '单' : '双';
            return colorText + typeText;
        }
    }
    
    // 处理生肖
    if (ZODIAC_NAMES[number]) {
        return ZODIAC_NAMES[number];
    }
    
    // 处理大小单双
    const basicMap = {
        'big': '大',
        'small': '小',
        'odd': '单',
        'even': '双'
    };
    if (basicMap[number]) {
        return basicMap[number];
    }
    
    // 如果是数字，确保两位显示
    // if (!isNaN(number)) {
    //     return String(number).padStart(2, '0');
    // }
    
    return number;
};

// 添加新的格式化函数
export const formatBetDetails = (betDetails) => {
  // 按金额分组
  const groupedBets = betDetails.reduce((groups, bet) => {
      const amount = bet.amount;
      if (!groups[amount]) {
          groups[amount] = [];
      }
      groups[amount].push(bet);
      return groups;
  }, {});

  // 格式化每个金额组
  return Object.entries(groupedBets).map(([amount, bets]) => {
    // 检查是否是固定个数的组合下注
    const isFixedCombo = bets.every(bet => bet.number.includes(','));
    
    if (isFixedCombo) {
        // 对于固定组合的下注，每个组合用[]括起来
        const numbers = bets.map(bet => `[${bet.number}]`).join(' ');
        return `${numbers} /${amount}`;
    } else {
        // 其他类型的下注保持原样
        const numbers = bets.map(bet => formatBetNumber(bet.number)).join(',');
        return `${numbers} /${amount}`;
    }
}).join('  ');
};
