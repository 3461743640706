<template>
  <div class="lottery-orders">
    <el-card>
      <!-- 搜索栏 -->
      <div class="toolbar">
        <div class="search-group">
          <el-select
            v-model="selectedLotteryId"
            placeholder="选择彩种"
            @change="handleLotteryChange"
            style="width: 200px; margin-right: 10px"
            popper-class="lottery-select-dropdown"
          >
            <el-option
              v-for="item in lotteryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-select
            v-model="searchForm.status"
            placeholder="订单状态"
            style="width: 120px"
            @change="handleSearch"
            clearable
            popper-class="lottery-select-dropdown"
          >
            <el-option
              v-for="(text, value) in orderStatusMap"
              :key="value"
              :label="text"
              :value="Number(value)"
            />
          </el-select>
          <el-input
            v-model="searchForm.issue"
            placeholder="搜索期号"
            style="width: 200px; margin-right: 10px"
            clearable
            @clear="handleSearch"
            @keyup.enter.native="handleSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
          <el-button 
            type="success" 
            icon="el-icon-refresh" 
            :loading="loading"
            @click="handleRefresh"
          >刷新</el-button>
        </div>
      </div>

      <!-- PC端表格 -->
      <el-table
        v-if="!isMobile"
        :data="orderList"
        v-loading="loading"
        style="width: 100%; margin-top: 15px"
        :header-cell-style="{
          padding: '6px 0',
          background: '#f5f7fa',
          height: '32px'
        }"
        :cell-style="{
          padding: '3px 0',
          height: '32px'
        }"
      >
        <el-table-column prop="issueNumber" label="期号" width="120" />
        <el-table-column prop="username" label="用户名" width="100" />
        <el-table-column label="玩法" width="160">
          <template slot-scope="scope">
            {{ scope.row.mainPlay }} - {{ scope.row.subPlay }}
          </template>
        </el-table-column>
        <el-table-column label="投注内容" min-width="150">
          <template slot-scope="scope">
            <span class="bet-text">
              {{ formatBetDetails(scope.row.betDetails, scope.row.lotteryCode) }}元
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="totalAmount" label="总金额" width="90" align="right">
          <template slot-scope="scope">
            {{ scope.row.totalAmount.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="winningAmount" label="中奖金额" width="90" align="right">
          <template slot-scope="scope">
            {{ scope.row.winningAmount > 0 ? scope.row.winningAmount.toFixed(2) : '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="投注时间" width="150" />
        <el-table-column label="状态" width="70">
          <template slot-scope="scope">
            <el-tag :type="getStatusType(scope.row.status)" size="mini">
              {{ getStatusText(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70" fixed="right">
          <template slot-scope="scope">
            <el-button 
              type="text" 
              @click="handleViewDetail(scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端卡片列表 -->
      <div v-else class="mobile-list">
        <el-card
          v-for="item in orderList"
          :key="item.id"
          class="mobile-item"
          shadow="hover"
        >
          <div class="mobile-item-header">
            <div class="mobile-item-info">
              <div class="mobile-item-title">
                期号：{{ item.issueNumber }}
                <el-tag :type="getStatusType(item.status)" size="mini" class="status-tag">
                  {{ getStatusText(item.status) }}
                </el-tag>
              </div>
              <div class="mobile-item-desc">用户：{{ item.username }}</div>
            </div>
          </div>
          <div class="mobile-item-body">
            <div class="info-row">
              <span class="label">玩法：</span>
              <span class="value">{{ item.mainPlay }} - {{ item.subPlay }}</span>
            </div>
            <div class="info-row">
              <span class="label">投注：</span>
              <div class="bet-details">
                <div v-for="(group, index) in getGroupedBets(item.betDetails, item.lotteryCode)" :key="index" class="bet-item">
                  <span class="bet-number">{{ group.numbers }}</span>
                  <span class="bet-amount">/{{ group.amount }}元</span>
                </div>
              </div>
            </div>
            <div class="info-row">
              <span class="label">金额：</span>
              <span class="value highlight">{{ item.totalAmount.toFixed(2) }}元</span>
              <span v-if="item.winningAmount > 0" class="winning">
                中奖：{{ item.winningAmount.toFixed(2) }}元
              </span>
            </div>
            <div class="info-row">
              <span class="label">时间：</span>
              <span class="value time">{{ item.created_at }}</span>
            </div>
          </div>
          <div class="mobile-item-footer">
            <el-button 
              type="primary" 
              size="mini" 
              @click="handleViewDetail(item)"
            >
              查看详情
            </el-button>
          </div>
        </el-card>
      </div>

      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        />
      </div>
    </el-card>

    <!-- 添加详情对话框 -->
    <el-dialog
      title="订单详情"
      :visible.sync="detailVisible"
      :width="isMobile ? '95%' : '600px'"
      :fullscreen="isMobile"
      custom-class="order-detail-dialog"
    >
      <div v-if="currentOrder" class="order-detail">
        <div class="detail-section">
          <div class="section-title">基本信息</div>
          <div class="detail-row">
            <span class="label">期号：</span>
            <span class="value">{{ currentOrder.issueNumber }}</span>
          </div>
          <div class="detail-row">
            <span class="label">用户名：</span>
            <span class="value">{{ currentOrder.username }}</span>
          </div>
          <div class="detail-row">
            <span class="label">投注时间：</span>
            <span class="value">{{ currentOrder.created_at }}</span>
          </div>
          <div class="detail-row">
            <span class="label">状态：</span>
            <span class="value">
              <el-tag :type="getStatusType(currentOrder.status)" size="small">
                {{ getStatusText(currentOrder.status) }}
              </el-tag>
            </span>
          </div>
        </div>

        <div class="detail-section">
          <div class="section-title">投注信息</div>
          <div class="detail-row">
            <span class="label">玩法：</span>
            <span class="value">{{ currentOrder.mainPlay }} - {{ currentOrder.subPlay }}</span>
          </div>
          <div class="detail-row">
            <span class="label">投注内容：</span>
            <div class="bet-details">
              <div v-for="(group, index) in getGroupedBets(currentOrder.betDetails, currentOrder.lotteryCode)" 
                   :key="index" 
                   class="bet-item"
              >
                <span class="bet-number">{{ group.numbers }}</span>
                <span class="bet-amount">/{{ group.amount }}元</span>
              </div>
            </div>
          </div>
          <div class="detail-row">
            <span class="label">投注金额：</span>
            <span class="value amount">{{ currentOrder.totalAmount.toFixed(2) }}元</span>
          </div>
        </div>

        <div v-if="currentOrder.status === 1" class="detail-section">
          <div class="section-title">中奖信息</div>
          <div class="detail-row">
            <span class="label">中奖金额：</span>
            <span class="value win-amount">
              {{ currentOrder.winningAmount.toFixed(2) }}元
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatBetNumber as formatLhcBetNumber, formatBetDetails as formatLhcBetDetails } from '@/config/lhc.config.js'

export default {
  name: 'LotteryOrders',
  data() {
    return {
      loading: false,
      selectedLotteryId: null,
      searchForm: {
        issue: '',
        status: 0  // 默认未开奖
      },
      orderList: [],
      lotteryList: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      isMobile: false,
      // 订单状态映射
      orderStatusMap: {
        0: '未开奖',
        1: '已中奖',
        2: '未中奖'
      },
      // 投注类型映射
      betTypeMap: {
        NUMBER: 'number',      // 数字
        ZODIAC: 'zodiac',     // 生肖
        SIZE: 'size',         // 大小
        ODDEVEN: 'oddeven',   // 单双
        HEAD: 'head',         // 头数
        TAIL: 'tail',         // 尾数
        COMBO: 'combo'        // 组合
      },
      detailVisible: false,
      currentOrder: null
    }
  },
  methods: {
    // 检查设备类型
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },

    // 获取彩票列表
    async fetchLotteryList() {
      try {
        const res = await this.$http.get('/api/admin/lottery/list')
        if (res.data.code === 200) {
          this.lotteryList = res.data.data.list
          // 从 localStorage 获取上次选择的彩种
          const savedLotteryId = localStorage.getItem('selectedLotteryId')
          if (savedLotteryId && this.lotteryList.some(item => item.id === Number(savedLotteryId))) {
            this.selectedLotteryId = Number(savedLotteryId)
          } else if (this.lotteryList.length > 0) {
            this.selectedLotteryId = this.lotteryList[0].id
          }
          this.fetchData()
        }
      } catch (error) {
        console.error('获取彩票列表失败:', error)
        this.$message.error('获取彩票列表失败')
      }
    },

    // 获取订单数据
    async fetchData() {
      if (!this.selectedLotteryId) return
      
      this.loading = true
      try {
        const params = {
          page: this.currentPage,
          pageSize: this.pageSize,
          lotteryId: this.selectedLotteryId,
          issue: this.searchForm.issue,
          status: this.searchForm.status
        }
        
        console.log('请求参数:', params)
        const res = await this.$http.get('/api/admin/lottery/orders', { params })
        console.log('响应数据:', res.data)
        
        if (res.data.code === 200) {
          this.orderList = res.data.data.list
          this.total = res.data.data.total
          console.log('订单列表:', this.orderList)
        }
      } catch (error) {
        console.error('获取投注记录失败:', error)
        this.$message.error('获取数据失败')
      } finally {
        this.loading = false
      }
    },

    // 处理彩种切换
    handleLotteryChange() {
      // 存储选择的彩种ID
      localStorage.setItem('selectedLotteryId', this.selectedLotteryId)
      this.currentPage = 1
      this.fetchData()
    },

    // 分页大小改变
    handleSizeChange(val) {
      this.pageSize = val
      this.fetchData()
    },

    // 页码改变
    handleCurrentChange(val) {
      this.currentPage = val
      this.fetchData()
    },

    // 获取彩票图标
    getLotteryIcon(id) {
      const lottery = this.lotteryList.find(item => item.id === id)
      return lottery?.icon || ''
    },

    // 获取彩票名称
    getLotteryName(id) {
      const lottery = this.lotteryList.find(item => item.id === id)
      return lottery?.name || '未知彩种'
    },

    // 获取状态类型
    getStatusType(status) {
      switch (status) {
        case 0: return 'info'    // 未开奖
        case 1: return 'success' // 已中奖
        case 2: return 'danger'  // 未中奖
        default: return 'info'
      }
    },

    // 获取状态文本
    getStatusText(status) {
      return this.orderStatusMap[status] || '未知'
    },

    // 添加搜索处理方法
    handleSearch() {
      this.currentPage = 1
      this.fetchData()
    },

    // 判断是否为数字投注
    isNumberBet(betType) {
      return betType === this.betTypeMap.NUMBER
    },

    // 查看详情
    handleViewDetail(order) {
      this.currentOrder = order
      this.detailVisible = true
    },

    // 格式化投注内容
    formatBetNumber(number, lotteryCode) {
      switch(lotteryCode) {
        case 'lhc':
          return formatLhcBetNumber(number);
        case 'ssc':
          // return formatSscBetNumber(number);
          return number; // 暂时直接返回
        case 'k3':
          // return formatK3BetNumber(number);
          return number; // 暂时直接返回
        default:
          return number;
      }
    },

    // 格式化投注内容
    formatBetDetails(betDetails, lotteryCode) {
      switch(lotteryCode) {
        case 'lhc':
          // 处理六合彩的投注详情
          return betDetails.map(bet => {
            if (bet.number.includes(',')) {
              // 处理组合投注
              const numbers = bet.number.split(',');
              return `[${numbers.map(num => formatLhcBetNumber(num)).join(',')}]/${bet.amount}`;
            }
            return `${formatLhcBetNumber(bet.number)}/${bet.amount}`;
          }).join(', ');
        case 'ssc':
          return betDetails.map(bet => `${bet.number}/${bet.amount}`).join(', ');
        case 'k3':
          return betDetails.map(bet => `${bet.number}/${bet.amount}`).join(', ');
        default:
          return betDetails.map(bet => `${bet.number}/${bet.amount}`).join(', ');
      }
    },

    // 按金额和赔率分组的辅助方法
    getGroupedBets(betDetails, lotteryCode) {
      // 按金额和赔率分组
      const groups = betDetails.reduce((acc, bet) => {
        const key = `${bet.amount}_${bet.odds}`;
        if (!acc[key]) {
          acc[key] = {
            bets: [],
            amount: bet.amount,
            odds: bet.odds
          };
        }
        acc[key].bets.push(bet);
        return acc;
      }, {});
      
      // 转换为数组并格式化号码
      return Object.values(groups).map(group => {
        // 检查是否是固定个数的组合下注
        const isFixedCombo = group.bets.every(bet => bet.number.includes(','));
        
        let numbers;
        if (isFixedCombo) {
          // 对于固定组合的下注，每个组合用[]括起来
          numbers = group.bets.map(bet => {
            const nums = bet.number.split(',');
            return `[${nums.map(num => this.formatBetNumber(num, lotteryCode)).join(',')}]`;
          }).join(' ');
        } else {
          // 其他类型的下注保持原样
          numbers = group.bets.map(bet => this.formatBetNumber(bet.number, lotteryCode)).join(',');
        }
        
        return {
          ...group,
          numbers
        };
      });
    },

    // 添加刷新方法
    handleRefresh() {
      this.fetchData()
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchLotteryList()
  }
}
</script>

<style lang="scss" scoped>
.lottery-orders {
  padding: 20px;

  .toolbar {
    display: flex;
    align-items: center;
    
    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
      
      .el-select {
        width: 100% !important;
      }
    }
  }

  .lottery-info {
    display: flex;
    align-items: center;
    font-size: 13px;
    
    .lottery-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80px;
    }
  }

  .mobile-list {
    margin-top: 15px;

    .mobile-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .mobile-item-header {
        margin-bottom: 8px;

        .mobile-item-info {
          .mobile-item-title {
            font-size: 15px;
            font-weight: 500;
            color: #303133;
            margin-bottom: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .status-tag {
              margin-left: 8px;
            }
          }

          .mobile-item-desc {
            font-size: 13px;
            color: #909399;
          }
        }
      }

      .mobile-item-body {
        .info-row {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          font-size: 13px;

          &:last-child {
            margin-bottom: 0;
          }

          .label {
            color: #909399;
            width: 45px;
            flex-shrink: 0;
          }

          .value {
            flex: 1;
            color: #303133;

            &.highlight {
              color: #f56c6c;
              font-weight: 500;
            }

            &.time {
              color: #909399;
              font-size: 12px;
            }
          }

          .odds {
            margin-left: 15px;
            color: #909399;
          }
        }
      }
      .mobile-item-footer {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        .el-button {
          width: 100%;
          height: 36px;
          font-size: 14px;
          color: #fff;
          background-color: #409EFF;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: #66b1ff;
          }
        }
      }
    }
  }

  .pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  // 调整表格样式
  :deep(.el-table) {
    // 调整表头字体
    th {
      font-size: 13px;
      font-weight: 500;
    }
    
    // 调整单元格字体
    td {
      font-size: 13px;
    }

    // 调整表格行高
    .el-table__row {
      height: 32px;
    }
  }

  // 调整状态标签大小
  :deep(.el-tag--mini) {
    height: 20px;
    line-height: 18px;
    padding: 0 6px;
  }
}

// 响应式适配
@media screen and (max-width: 768px) {
  .lottery-orders {
    padding: 10px;

    .toolbar {
      flex-direction: column;
      align-items: stretch;

      .left, .right {
        width: 100%;

        .el-select, .el-input, .el-date-picker {
          width: 100% !important;
        }
      }
    }

    .pagination-container {
      .el-pagination {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .mobile-item {
    .mobile-item-header {
      .mobile-item-info {
        .mobile-item-title {
          color: #fff !important;
        }
      }
    }

    .mobile-item-body {
      .info-row {
        .value {
          color: #fff !important;

          &.time {
            color: #909399 !important;
          }
        }
      }
    }
  }
}

.bet-text {
  color: #606266;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 移动端文本样式
.mobile-list {
  .mobile-item {
    .mobile-item-body {
      .info-row {
        .value {
          font-size: 14px;
        }
      }
    }
  }
}

.order-detail {
  .detail-section {
    margin-bottom: 24px;
    
    &:last-child {
      margin-bottom: 0;
    }

    .section-title {
      font-size: 16px;
      font-weight: 500;
      color: #303133;
      margin-bottom: 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid #EBEEF5;
    }

    .detail-row {
      display: flex;
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        width: 80px;
        color: #909399;
        flex-shrink: 0;
      }

      .value {
        flex: 1;
        color: #303133;

        &.highlight {
          color: #409EFF;
        }

        &.amount {
          color: #F56C6C;
          font-weight: 500;
        }

        &.win-amount {
          color: #67C23A;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}

// 移动端适配
@media screen and (max-width: 768px) {
  .order-detail {
    .detail-section {
      margin-bottom: 20px;

      .section-title {
        font-size: 15px;
        margin-bottom: 12px;
        padding-bottom: 6px;
      }

      .detail-row {
        margin-bottom: 10px;
        font-size: 13px;

        .label {
          width: 70px;
        }
      }
    }
  }
}

// 暗色模式适配
@media (prefers-color-scheme: dark) {
  .order-detail {
    .detail-section {
      .section-title {
        color: #fff;
        border-bottom-color: #363636;
      }

      .detail-row {
        .value {
          color: #fff;
        }
      }
    }
  }
}

.bet-details {
  .bet-item {
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    font-size: 13px;

    &:last-child {
      margin-bottom: 0;
    }

    .bet-number {
      font-weight: 500;
      color: #333;
    }

    .bet-amount {
      color: #F56C6C;
    }

    .bet-odds {
      color: #909399;
      font-size: 12px;
    }
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .bet-details {
    .bet-item {
      .bet-number {
        color: #E5EAF3;
      }
    }
  }
}
</style>

<style lang="scss">
// 下拉框样式
.lottery-select-dropdown {
  @media screen and (max-width: 768px) {
    width: 90vw !important;
    margin-left: 5vw;
    max-width: none !important;
    
    .el-select-dropdown__wrap {
      max-height: 50vh !important;
      padding-bottom: env(safe-area-inset-bottom);
    }
    
    .el-select-dropdown__item {
      height: 44px;
      line-height: 44px;
      padding: 0 20px;
      font-size: 16px;
      
      &:last-child {
        margin-bottom: 8px;
      }
    }

    // 添加底部安全区域的内边距
    &.el-popper[x-placement^="bottom"] {
      margin-bottom: 8px;
      padding-bottom: env(safe-area-inset-bottom);
    }
    
    // 确保弹出框在视口内
    &.el-select-dropdown.is-multiple {
      .el-select-dropdown__wrap {
        padding-bottom: calc(8px + env(safe-area-inset-bottom));
      }
    }
  }
}

// 修改移动端搜索栏样式
@media screen and (max-width: 768px) {
  .search-group {
    .el-select {
      margin-bottom: 10px !important;
      
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style> 